
import Vue from "vue"
import config from "@/app.config"
import i18n from "@/i18n"
import { reset } from "@/db"
import Confirm from "@/components/Confirm.vue"
import packageJson from "@/../package.json"

export default Vue.extend({
  name: "SettingsScreen",

  components: {
    Confirm
  },

  data: () => ({
    currentLocale: "no",
    languages: [
      { label: "English", locale: "en" },
      { label: "Norwegian", locale: "no" }
    ],
    appVersion: packageJson.version,
    config: {}
  }),

  watch: {
    currentLocale: {
      handler(v): void {
        i18n.locale = v
        this.$vuetify.lang.current = v
        localStorage.currentLocale = v
      }
    }
  },

  created() {
    this.config = config
    this.currentLocale = localStorage.currentLocale || "no"
  },

  methods: {
    checkForUpdates(): void {
      if(config.runtime !== "app" || !window.ipc) return
      window.ipc.send("CHECK_FOR_UPDATES")
    },

    async resetApp(): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("resetConfirmation")
      )) return
      await reset()
      this.$success(this.$t("resetSuccess"))
    }
  }
})
